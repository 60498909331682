const channelConfig = {
  facebook: {
    text: 'Share on Facebook',
    getUrl: (publicUrl) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(publicUrl)}`
    }
  },
  twitter: {
    text: 'Share on Twitter',
    getUrl: (publicUrl) => {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(publicUrl)}`
    }
  },
  linkedin: {
    text: 'Share on LinkedIn',
    getUrl: (publicUrl) => {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(publicUrl)}`
    }
  },
  whatsapp: {
    text: 'Share on WhatsApp',
    getUrl: (publicUrl) => {
      return `https://wa.me/?text=${encodeURIComponent(publicUrl)}`
    }
  },
}

export default function ShareForm({ action, onSubmit, publicUrl }) {
  const { config } = action

  return <div className="share-form">
    { config.channels.map(channel => {
      const { text, getUrl = () => {} } = channelConfig[channel] || {}
      return <div className='margin-bottom'>
        <a key={channel} className={`share-button ${channel} flex align-center gap-8 justify-center`} target="_blank" href={getUrl(publicUrl)}>{text}</a>
      </div>
    }) }

    <div>
      <a className='flex align-center gap-8 justify-center cursor-pointer' onClick={onSubmit}>
        <img src='images/socials/black/copy.svg' width="16" height="16" /> No thanks
      </a>
    </div>
  </div>
}
