import { useAct } from 'FlowTaker/store'
import SurveyAction from './Actions/SurveyAction'
import ShareAction from './Actions/ShareAction'
import RedirectAction from './Actions/RedirectAction'
import './PageContainer.scoped.scss'

export default function PageContainer({ flowId, forget }) {
  const act = useAct()
  const actionId = useSel((s) => s.taker.flowTaker.currentActionId)
  const action = useSel((s) => s.taker.flow.actions.find((a) => a.id === actionId) || {})
  const { kind, surveyId } = action

  const returnToEditorStyles = {
    background: 'black',
    color: 'white',
    width: '100%',
    padding: '15px',
  }

  useEffect(() => {
    act.taker.load(flowId, { forget })
  }, [])

  const isAdmin = _.includes(window.ENV.CURRENT_USER_PERMISSIONS || [], 'manage_admin')

  function Content() {
    if (kind === 'survey') {
      return <SurveyAction actionId={actionId} surveyId={surveyId} />
    } else if (kind === 'share') {
      return <ShareAction actionId={actionId} />
    } else if (kind === 'redirect') {
      return <RedirectAction actionId={actionId} />
    } else {
      return <div>Unknown action kind: {kind}</div>
    }
  }

  return (
    <div>
      {isAdmin && (
        <div style={returnToEditorStyles}>
          <a href={`/admin/flows/${flowId}/build?nav=preview`} style={{ color: 'white' }}>
            Return to editor
          </a>
        </div>
      )}
      <Content />
    </div>
  )
}
